import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Foot  from '../components/Foot'
import Ethics from '../components/Ethics'
import bull from '../assets/images/istockphoto-1158762452-1024x1024.jpg'
import connect from '../assets/images/connect.png'



const ReputationManagement = props => (
  <Layout>
  <Helmet
  htmlAttributes={{ lang: 'en' }}
  title="Reputation Management - Bulls i Marketing"
  meta={[
    { name: 'description', content: 'Develop Your Strategic Internet Marketing Plan (SIMP)' },
    { name: 'keywords', content: 'SIMP, Reputation Management, Bulls i Marketing, Keyword Report, Initial Ranking, Benchmark, Site Crawl, Link Profile Analysis, ' },
  ]}
></Helmet>

    <div id="main" className="wrapper style2">
      <div className="container">
      <article>
        <header className="major">
          <h2>Reputation Management</h2>
        </header>

   
        <section>
            <h2>First Step: Research</h2>
            <p>The first step taken with a client is critical research. Our detailed initial analysis reports build the foundation for a strong search engine reputation management success possible and determine the best way of getting a return on your investment. The analysis report is a huge amount of information we need to gather to take action and direction with your websites’ projects. This initial data collection takes us two to three weeks to research the following data, then this data is forwarded to you in an online meeting. These analysis reports allow everyone to understand the scope of the project and will allow us to set up a Strategic Internet Marketing Plan (SIMP).</p>

            <h3>These Reports Include:</h3>
            <ol>
                <li><b>Keyword Report</b> Determines exact search phrases and keywords as well as the monthly search volume used people that generate negative results for you or your brand. This report provides us an estimate of how severe and huge the problem is.</li>
                <li><b>Initial Ranking (Benchmark)</b> This critical component ensures ranking positions for your brand and for each URL that used negative content about your business and it also ensures that you know about all the potentially negative results.</li>
                <li><b>Link Profile Analysis</b> A detailed report on all inbound links, anchor texts, and age of these links, and it is used to find out toxic backlinks that may be preventing your website from ranking higher than negative results. </li>
                <li><b>Site Crawl</b> This report includes a full crawl of all webpages, images, and all other elements necessary for your website to function. It also helps to determine if there are any structural issues that may be affecting current ranking in a search engine.</li>
                <li><b>Offending Result Strength Analysis</b> It is all about what’s pushing us back and how long it’s going to take to turn negative results into positive ones.</li>
                <li><b>Positive Asset Strength Analysis</b> We should also know what we positive assets we have to solve the problems that hurdle our rankings. These positive assets are Existing social media assets, microsites, affiliate sites, other entities owing sites that have positive content, etc.</li>
                <li><b>On Page Optimization Recommendations</b> From title tags and meta descriptions to keyword density. Already existing on page optimization of our positive assets must be taken into account to fix the issues.</li>
                <li><b>Creation of a Content Development Strategy</b> Once we know what issues we are facing and what we are up against, we’ll create a list of recommendations on what type of content needs to be posted to combat the problem.</li>
                <li><b>Strategy & Scope of Work Developmen</b> Develop and recommend a strategy and scope of work that will “demote” the results from search page one, and below if possible. The strategy will also constitute the expectation for costs and timeframes.</li>
            </ol>

            <hr />

        </section>

        <section>
            <h2>Second Step: Develop Your Strategic Internet Marketing Plan (SIMP)</h2>
            <p>Based on our data collection, we develop a custom SIMP that determines our way of dominating the rankings in an organic search engine.
            </p>

            <h3>This Plan Includes:</h3>


            <section>
                <p><b>Plans for your website:</b> What do we need to combat? Is the website code synced with the latest search engine algorithms? Is there a way for search engines to put up optimized content? Can the already existing website be optimized?</p>
                <p>What website assets do we have? Do you own other websites that can help us fix your reputation management issue? To rank positive content on the first page, we will probably need several sites that are or can be optimized for the content to be successful since it’s very rare that 1-2 sites alone fix the reputation management issue. If you don’t have high authority web assets available, we will help you acquire some. All of these expenses are directly at your company. </p>
                <p>A financial order for your website’s keywords. Some keywords affect your business more and they expose negative content than others. We will try to incrementally remove negative content from your customer’s eyes as quickly as we can and prioritize keywords by the largest search volume to least; hence generating a positive Return on Investment as dynamically as possible. </p>
            </section>

            <h3>Reputation Management METHODOLOGIES</h3>
            <section>
                <p><b>Website Development:</b> Initial launch will include the development of various websites that can be used to rank positive content up.</p>
            </section>
            <section>
                <p><b>On Page Optimization:</b> On Page Optimization comprises writing a new content copy that is properly optimized to be posted on the website. This content sticks to all the best practices like specific keyword density, keyword in alt tags, proper keywords in meta tags, keyword in the title, etc. If you like us to do this and provide back-end access to the site, we will publish optimized content for your team has reviewed and approved such content for posting on your main website.</p>
            </section>
            <section>
                <p><b>Off Page Optimization:</b> We never promise “x” number of backlinks since all backlinks are not equal and the amount doesn’t promise value to your or the end goal of ranking. We do promise quality backlinks that come from blogs, from those that specifically talk about the keyword we’re trying to optimize your content/site for, utilize keywords as the anchor text, and link back the articles we specifically want to rank for that keyword. We believe in a viable approach to backlinks and so believe in the control of such backlinks. We always monitor Google’s algorithms for updates, alter our strategy, and make changes to all the already developed backlinks for you. This is the methodology of how our clients are not only successful but also an incompetent winner in their industries.</p>
            </section>
            <section>
                <p><b>Toxic Backlink Removal:</b> We initially look for and regularly monitor toxic backlinks coming from not suitable neighborhoods, and whenever we find such backlinks, we’ll go through the process of disavowing them. We streamlined this process by constantly upgrading our software since Google announced its Disavow Tool. Our software locates toxic backlinks, post a request to the site to remove them, auto checks in a week to see if it’s removed and then submits a report to Google to officially disavow the said backlinks if they haven’t. This documentation is a requirement for Google to disavow such toxic backlinks. Our software is integrated with human intelligence and does this heavy lifting to ensure that only TOXIC backlinks are removed for the best possible results rather than investing an exceptionally great time if done manually.</p>
            </section>
            <section>
                <p><b>Social Signal Development:</b> Social signals starting to have a significant impact on SEO which will affect our reputation management attempts, that’s the reason we develop social signals to each new piece of content we create for a client. This task is done via our social signal network, +1s, and shares the content. We take on in a much more gradual manner with already posted content as Google would see many social signals about an old piece of content as outlandish.</p>
            </section>
            <section>
                <p><b>Weekly Meetings:</b> We believe that we should set up weekly meetings with our clients. We don’t believe that our tasks should take longer than they should, but SEO may take time. By setting up a regular meeting with Bulls i Marketing Company you can keep us liable, and we’ll keep your team and you accountable for the action items that need to be taken place. We’ll also be sharing reports of the progress of the campaign and keep you acknowledged the results of our relationship in these meetings.</p>
            </section>

            <div className="box alt">
                <div className="row gtr-50 gtr-uniform aln-center ">
                    <div className="col-12-small">
                        <span className="image fit">
                        <img src={bull} alt="" style={{maxHeight:"420px"}} />
                        </span>
                    </div>
                </div>
            </div>

            <Ethics />

        </section>

        </article>
      </div>
    </div>
    <Foot className="style3" title="Improve your Social Media Marketing now!"/>
  </Layout>
)

export default ReputationManagement
