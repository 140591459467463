import React from 'react'

const Ethics = props => {
    return(
        <section>
        <h2>Bulls i Marketing Company SEO Ethics</h2>
        <p>We only believe in the success achieved through white hat SEO, but our ethics are much more important to us; we litmus test everything we do. </p>
        <p><b>There are five questions that need to be answered of things we think, tell, or do:</b></p>
        <ul>
            <li>Is it all TRUTH?</li>
            <li>Is it all FAIR to all concerned?</li>
            <li>Will it build FRIENDSHIPS and GOODWILL?</li>
            <li>Will it all be BENEFICIAL to all concerned?</li>
            <li>Is it the RIGHT thing to execute?</li>
        </ul>
        <h3>We will neither engage ourselves in devious marketing methodologies, or black hat SEO strategies, nor we will work with organizations involved with gambling, adult themes, or activities which we find ethically questionable, or in something that may exploit anyone. </h3>
    </section>
    )
}

export default React.memo(Ethics);
